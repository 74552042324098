import React from "react"
import { Link } from "gatsby"
import { articleCard } from "./styles.module.less"

const ArticleCard = ({ headline, link, author, date, preview }) => {
  return (
    <div className={articleCard}>
      <h3>
        <Link to={link}>{headline}</Link>
      </h3>
      <p>
        <span>{author}</span>
      </p>
      <p>
        <span>{date}</span>
      </p>
      <p>
        <Link to={link} style={{ textDecoration: "none" }}>
          {preview}...
        </Link>
      </p>
    </div>
  )
}

export default ArticleCard
