import React from "react"
import Layout from "../layout"
import { Seo } from "../seo"
import ArticleCard from "../articleCard"

import { articlesContainer } from "./styles.module.less"

const articleList = [
  {
    title:
      "What You Should Know About Game Protection, Memory, and Sleight of Hand",
    link: "/what-you-should-know-about-memory",
    author: "Sal Piacente",
    date: "04/22/2020",
    preview:
      "Some of the biggest scams today, as well as some of the most effective advantage plays (legal methods used by players to gain an advantage over the house, as opposed to cheating), require memory skills that can be easily acquired with a bit of training",
  },
]

const Articles = () => {
  return (
    <Layout>
      <h1>Articles</h1>
      <div className={articlesContainer}>
        {articleList.map(item => {
          return (
            <ArticleCard
              headline={item.title}
              link={item.link}
              author={item.author}
              date={item.date}
              preview={item.preview}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default Articles

export const Head = () => <Seo title="Articles | Universal Game Protection" />
